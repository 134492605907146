// --------------------------------------------------------------------------------
// <copyright file="maxCuttingTime.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { FilterTimeSpanEnum } from '@/models/enums/FilterTimeSpanEnum';
import moment from 'moment/moment';
import { Shift } from '@/models/shift';

export function calculateMaxCuttingTime(
  deviceIds: number[],
  shifts: Shift[],
  timeFilter: FilterTimeSpanEnum | [string, string],
) {
  const deviceHours = deviceIds.reduce(
    (hours, deviceId) => hours + calculateDeviceShiftHours(deviceId, shifts),
    0,
  );

  return deviceHours * getTimeFilterMultiplier(timeFilter);
}

function calculateDeviceShiftHours(deviceId: number, shifts: Shift[]): number {
  if (shifts.length === 0) {
    // In case no shifts were selected
    return 24;
  }
  return shifts
    .filter((shift) => shift.relatedDevices.includes(deviceId))
    .reduce((hours, shift) => {
      const shiftLengthMinutes =
        shift.timeFromHh <= shift.timeToHh && shift.timeFromMm <= shift.timeToMm
          ? 60 * shift.timeToHh + shift.timeToMm - (60 * shift.timeFromHh + shift.timeFromMm)
          : 60 * (24 + shift.timeToHh) +
            shift.timeToMm -
            (60 * shift.timeFromHh + shift.timeFromMm);

      // Add adjustment to make it a full hour when the user enters 23:59 as end time
      const endAdjustment = shift.timeToMm === 59 ? 1 : 0;
      return hours + (shiftLengthMinutes + endAdjustment) / 60;
    }, 0);
}

function getTimeFilterMultiplier(timeFilter: FilterTimeSpanEnum | [string, string]): number {
  switch (timeFilter) {
    case FilterTimeSpanEnum.Day:
      return 1;
    case FilterTimeSpanEnum.Week:
      return moment().day(); // day of week
    case FilterTimeSpanEnum.Month:
      return moment().date(); // day of month
    case FilterTimeSpanEnum.Year:
      return moment().dayOfYear();
    default:
      return 0;
  }
}
